import { Page } from 'api/types';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useContentContext } from './ContentContext';
import { PageContent } from './PageContent';

export function ContentRouter() {
  const content = useContentContext();

  if (!content.pages.length) {
    return (
      <div className='flex-1 flex flex-col items-center justify-center uppercase text-lg'>
        No content.
      </div>
    );
  }

  return (
    <Routes>
      {content.pages.map((page) => (
        <Route key={page.title} path={getPagePath(page)} element={<PageContent page={page} />} />
      ))}
      <Route path='*' element={<Navigate to={getPagePath(content.pages[0])} />} />
    </Routes>
  );
}

export function getPagePath(page: Page): string {
  const path = page.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
  if (path === 'scripting') {
    return '/scripting-page';
  }
  return path;
}
