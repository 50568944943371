import { useMutation } from 'react-query';
import { BASE_URL } from './constants';
import { Content } from './types';

export function useContent() {
  return useMutation(fetchContent);
}

async function fetchContent(password?: string): Promise<Content> {
  const response = await fetch(`${BASE_URL}/scripting/content.php`, {
    method: 'POST',
    body: JSON.stringify({ password }),
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error(
      'Failed to fetch content: ' + response.statusText + ' (' + (await response.text()) + ')'
    );
  }

  return await response.json();
}
