import { RsvpParticipant, useRsvp } from 'api/useRsvp';
import { useRef, useState } from 'react';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { LoadingSpinner } from './LoadingSpinner';
import { YesNoToggle } from './YesNoToggle';

interface GuestData {
  name: string;
  food: 'meat' | 'vegan' | 'vegetarian';
}

interface RsvpFormData extends GuestData {
  attends: boolean;
  email: string;
  companions: GuestData[];
}

const ALREADY_SUBMITTED_ATTENDANCE_KEY = 'wedding.alreadySubmittedAttendance';

export function RsvpForm() {
  const [alreadySubmittedAttendance, setAlreadySubmittedAttendance] = useState(
    localStorage.getItem(ALREADY_SUBMITTED_ATTENDANCE_KEY)
  );

  const submittedAttendance = useRef(false);
  const saveRsvp = useRsvp({
    onSuccess: () => {
      const attendance = submittedAttendance.current ? 'true' : 'false';
      localStorage.setItem(ALREADY_SUBMITTED_ATTENDANCE_KEY, attendance);
      setAlreadySubmittedAttendance(attendance);
    },
  });

  const form = useForm<RsvpFormData>({
    defaultValues: {
      email: '',
      name: '',
      companions: [],
      attends: true,
      food: 'vegetarian',
    },
  });

  const attends = useController({
    control: form.control,
    name: 'attends',
  });
  const attendsValue = attends.field.value;

  const name = useController({
    control: form.control,
    name: 'name',
    rules: { required: true },
  });
  const food = useController({
    control: form.control,
    name: 'food',
  });

  const companions = useFieldArray({
    control: form.control,
    name: 'companions',
  });

  const email = useController({
    control: form.control,
    name: 'email',
    rules: {
      validate: (value) => {
        return attendsValue ? !!value : true;
      },
    },
  });

  const onAddCompanion = () => {
    companions.append({ name: '', food: 'vegetarian' });
  };
  const onRemoveCompanion = (index: number) => {
    companions.remove(index);
  };

  const onSubmit = (data: RsvpFormData) => {
    const participants: RsvpParticipant[] = [
      {
        name: data.name,
        food: data.food,
      },
    ];
    data.companions.forEach((companion) => {
      participants.push({
        name: companion.name,
        food: companion.food,
      });
    });

    submittedAttendance.current = data.attends;
    saveRsvp.mutate({
      attends: data.attends,
      email: data.email,
      participants,
    });
  };

  const onChangeSubmission = () => {
    setAlreadySubmittedAttendance(null);
  };

  if (alreadySubmittedAttendance !== null) {
    return (
      <>
        <YesNoToggle
          className='self-center'
          disabled
          yesLabel='Ich / Wir kommen'
          noLabel='Ich / Wir kommen nicht'
          value={alreadySubmittedAttendance === 'true'}
        />
        <div className='text-center text-4xl'>
          {alreadySubmittedAttendance === 'true' ? '🎉🎉🎉' : '😢😢😢'}
        </div>
        <div className='invitation text-center text-xl w-[250px]'>
          {alreadySubmittedAttendance === 'true' ? (
            <>Wir freuen uns auf eine schöne Feier!</>
          ) : (
            <>Treulose Tomate!</>
          )}
        </div>
        <button className='link' onClick={onChangeSubmission}>
          Ändern
        </button>
      </>
    );
  }

  return (
    <form className='form w-full sm:max-w-[300px]' onSubmit={form.handleSubmit(onSubmit)}>
      <YesNoToggle
        className='self-center mb-4'
        yesLabel='Ich / Wir kommen'
        noLabel='Ich / Wir kommen nicht'
        {...attends.field}
      />

      <label>
        <span>Name</span>
        <input type='text' {...name.field} />
      </label>
      <label>
        <span>Essenswunsch</span>
        <select {...food.field}>
          <option value='vegan'>🌱 Vegan</option>
          <option value='vegetarian'>🧀 Vegetarisch</option>
          <option value='meat'>🦴 Fleisch</option>
        </select>
      </label>

      {companions.fields.map((companion, index) => (
        <div key={companion.id} className='flex flex-row gap-2 mt-4'>
          <div className='flex-1 flex flex-col gap-2'>
            <label>
              <span>Begleitung {index + 1}</span>
              <input type='text' {...form.register(`companions.${index}.name`)} />
            </label>

            <label>
              <span>Essenswunsch</span>
              <select {...form.register(`companions.${index}.food`)}>
                <option value='vegan'>🌱 Vegan</option>
                <option value='vegetarian'>🧀 Vegetarisch</option>
                <option value='meat'>🦴 Fleisch</option>
              </select>
            </label>
          </div>
          <button
            className='hover:bg-stone-100 rounded-sm px-1'
            tabIndex={-1}
            type='button'
            onClick={() => onRemoveCompanion(index)}
          >
            ➖
          </button>
        </div>
      ))}

      <button
        type='button'
        className='link self-start mt-4'
        onClick={onAddCompanion}
        disabled={!name.field.value || saveRsvp.isLoading}
      >
        Begleitung hinzufügen
      </button>

      <p className='mb-4 text-sm'>Bitte gebt alle Menschen an die kommen.</p>

      {attendsValue && (
        <label className='mb-4'>
          <span>E-Mail</span>
          <input type='email' {...email.field} />
        </label>
      )}

      <button
        className='btn-primary text-center'
        type='submit'
        disabled={!form.formState.isValid || saveRsvp.isLoading}
      >
        {saveRsvp.isLoading && <LoadingSpinner size='xs' color='white' className='mr-2' />}
        {attends.field.value ? 'Zusagen 🎉' : 'Absagen 😞'}
      </button>

      {saveRsvp.isError && (
        <div className='text-red-500 text-center mt-4'>
          Da ist etwas schief gegangen, bitte erneut probieren.
        </div>
      )}
    </form>
  );
}
