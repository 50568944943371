import { AuthorizedContent } from 'api/types';
import React from 'react';

const Context = React.createContext<AuthorizedContent | undefined>(undefined);

export function ContentProvider({
  content,
  children,
}: {
  content: AuthorizedContent;
  children: React.ReactNode;
}) {
  return <Context.Provider value={content}>{children}</Context.Provider>;
}

export function useContentContext(): AuthorizedContent {
  const content = React.useContext(Context);
  if (!content) {
    throw new Error('useContent must be used within a ContentProvider');
  }
  return content;
}
