import { LoadingSpinner } from 'components/LoadingSpinner';
import { useEffect, useState } from 'react';

interface LoginProps {
  loading?: boolean;
  wrongPassword?: boolean;
  onLogin: (password: string) => void;
}

export function Login({ onLogin, loading, wrongPassword }: LoginProps) {
  const prefilledPassword = new URLSearchParams(window.location.search).get('password');
  const [password, setPassword] = useState(prefilledPassword || '');

  useEffect(() => {
    if (prefilledPassword) {
      onLogin(prefilledPassword);
    }
  }, [onLogin, prefilledPassword]);

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <h1 className='font-extrabold heading text-4xl text-center text-primary mb-12'>
        Anna &amp; Michael
      </h1>
      <label className='flex flex-col items-center gap-2'>
        <span className='uppercase'>Passwort</span>
        <input
          type='password'
          className='text-center'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        {wrongPassword && <span className='text-red-500'>Falsches Passwort</span>}
      </label>
      <button
        className='btn-primary mt-6 flex flex-row items-center'
        disabled={!password || loading}
        onClick={() => onLogin(password)}
      >
        {loading && <LoadingSpinner size='xs' color='white' className='mr-2' />}
        <span>Log in for Love</span>
      </button>
    </div>
  );
}
