import { Page } from 'api/types';
import parse, { Element, HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { RsvpForm } from './RsvpForm';

const reactParserOptions: HTMLReactParserOptions = {
  replace: (element) => {
    if (!(element instanceof Element)) {
      return;
    }

    if (element.tagName === 'a') {
      const href = element.attribs.href;
      if (!href?.startsWith('/')) {
        return;
      }
      return (
        <Link className={element.attribs.class} to={href}>
          {domToReact(element.children, reactParserOptions)}
        </Link>
      );
    }

    if (element.tagName === 'div' && element.attribs['data-component'] === 'rsvp-form') {
      return <RsvpForm />;
    }
  },
};

interface PageContentProps {
  page: Page;
}

export function PageContent({ page }: PageContentProps) {
  return (
    <div className='flex flex-col gap-4 self-center max-w-[700px]'>
      {parse(page.content, reactParserOptions)}
    </div>
  );
}
