import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useContentContext } from './ContentContext';
import { getPagePath } from './ContentRouter';
import styles from './NavMenu.module.less';

export function NavMenu() {
  const content = useContentContext();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = () => {
      const contentRoot = document.getElementById('contentroot');
      if (menuRef.current && contentRoot) {
        contentRoot.style.paddingTop = `${menuRef.current.offsetHeight + 50}px`;
      }
    };
    window.addEventListener('resize', listener);
    listener();

    return () => window.removeEventListener('resize', listener);
  }, []);

  return (
    <div
      ref={menuRef}
      className='flex flex-col items-center gap-2 px-3 py-6 backdrop-blur-sm mb-6 fixed w-screen'
    >
      <span className='text-center heading text-3xl text-primary'>Anna &amp; Michael</span>

      {!!content.pages.length && (
        <div className='flex flex-row flex-wrap justify-center gap-4 text-lg'>
          {content.pages.map((page) => (
            <NavLink key={page.title} className={styles.navmenu__link} to={getPagePath(page)}>
              {page.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}
