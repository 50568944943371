import { useContent } from 'api/useContent';
import { ContentProvider } from 'components/ContentContext';
import { ContentRouter } from 'components/ContentRouter';
import { LoadingScreen } from 'components/LoadingScreen';
import { NavMenu } from 'components/NavMenu';
import { Login } from 'Login';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function Root() {
  const initialLoad = useRef(true);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location]);

  const { mutate: loadContent, ...content } = useContent();
  useEffect(() => {
    // Try loading the content without any password - we might be authorized already
    loadContent(undefined);
  }, [loadContent]);

  const onLogin = useCallback(
    (password: string): void => {
      initialLoad.current = false;
      loadContent(password);
    },
    [loadContent]
  );

  if (initialLoad.current) {
    if (!content.data) {
      return <LoadingScreen error={content.error} onReload={() => loadContent(undefined)} />;
    }
  }

  if (!content.data?.authorized) {
    return (
      <Login
        onLogin={onLogin}
        loading={content.isLoading}
        wrongPassword={!content.data?.authorized && !initialLoad.current && !content.isLoading}
      />
    );
  }

  return (
    <ContentProvider content={content.data}>
      <NavMenu />
      <div id='contentroot' className='flex flex-col px-4 pb-12'>
        <ContentRouter />
      </div>
    </ContentProvider>
  );
}
