import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';

interface YesNoToggleProps {
  className?: string;
  name?: string;
  yesLabel?: string;
  noLabel?: string;
  value?: boolean;
  onChange?(value: boolean): void;
  disabled?: boolean;
}

const yesNoToggleRef = forwardRef(function YesNoToggle(
  { className, name, yesLabel, noLabel, value, onChange, disabled }: YesNoToggleProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={classNames(className, 'flex flex-col items-stretch gap-2')} ref={ref}>
      {(!disabled || value) && (
        <button
          type='button'
          disabled={disabled}
          className={classNames(
            'flex flex-row flex-nowrap items-center gap-2 transition-opacity',
            !value && 'opacity-40',
            'hover:opacity-100'
          )}
          onClick={() => onChange?.(true)}
        >
          <span className='w-[80px] text-right text-primary font-semibold'>🎉 JA</span>
          <span>{yesLabel}</span>
        </button>
      )}
      {(!disabled || !value) && (
        <button
          type='button'
          disabled={disabled}
          className={classNames(
            'flex flex-row flex-nowrap items-center gap-2 transition-opacity',
            value && 'opacity-40',
            'hover:opacity-100'
          )}
          onClick={() => onChange?.(false)}
        >
          <span className='w-[80px] text-right text-primary font-semibold'>😞 NEIN</span>
          <span>{noLabel}</span>
        </button>
      )}
      <input type='hidden' name={name} value={value ? 'true' : 'false'} />
    </div>
  );
});

export const YesNoToggle = yesNoToggleRef;
