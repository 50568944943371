import { LoadingSpinner } from './LoadingSpinner';

interface LoadingScreenProps {
  error?: unknown;
  onReload?: () => void;
}

export function LoadingScreen({ error, onReload }: LoadingScreenProps) {
  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      {!error ? (
        <LoadingSpinner size='lg' color='primary' />
      ) : (
        <div className='flex flex-col gap-4 items-center'>
          <span className='text-center'>
            Beim Laden ist ein Fehler aufgetreten.
            <br />
            Bitte erneut versuchen.
          </span>
          <button className='btn-primary' onClick={onReload}>
            Neu Laden
          </button>
        </div>
      )}
    </div>
  );
}
