import { useMutation, UseMutationOptions } from 'react-query';
import { BASE_URL } from './constants';

export interface RsvpParticipant {
  name: string;
  food: string;
}

interface RsvpPayload {
  attends: boolean;
  email: string;
  participants: RsvpParticipant[];
}

export function useRsvp(options?: UseMutationOptions<void, unknown, RsvpPayload>) {
  return useMutation(fetchRsvp, options);
}

async function fetchRsvp(payload: RsvpPayload): Promise<void> {
  const response = await fetch(`${BASE_URL}/scripting/rsvp.php`, {
    method: 'POST',
    body: JSON.stringify(payload),
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error(
      'Failed to save rsvp: ' + response.statusText + ' (' + (await response.text()) + ')'
    );
  }
}
